<template>
  <div>
    <Transaction />
  </div>
</template>

<script>
import Transaction from '@/components/Transaction.vue'

export default {
  components: {
    Transaction,
  },
}
</script>
